import {globalAppState as initialState} from "../state";
import {
    ADD_TO_CART,
    CLEAR_CART,
    CLEAR_MESSAGE,
    GENERATE_IDEMPOTENCY_KEY,
    REMOVE_FROM_CART,
    RESET_TERMINAL,
    SET_HOTEL_CONFIG,
    SET_INVENTORY,
    SET_SHOP_ACTIVE_FILTER,
    SET_SHOP_FILTER,
    SHOW_MESSAGE,
    TOGGLE_HELP,
    TOGGLE_NO_RESERVATION,
    TOGGLE_SPINNER,CHANGE_LOCALE
}                                       from "../../constants/constants";
import {LOCATION_CHANGE}                from 'connected-react-router';
import {v4 as uuidv4}                   from 'uuid';


export const appReducer = (state = initialState.app, action) => {

    let cartList = [...state.cart.list];
    switch (action.type) {
        case CHANGE_LOCALE:
            return {
                ...state,
                locale: action.data.locale
            };
        case TOGGLE_SPINNER:
            let showLoader = action.data.showLoader;
            if (showLoader === undefined) {
                showLoader = !state.showLoader;
            }

            return {
                ...state,
                showLoader:    showLoader,
                loaderMessage: action.data.loaderMessage
            };
        case TOGGLE_HELP:

            let showHelp = action.data.showHelp;
            if (showHelp === undefined) {
                showHelp = !state.showHelp;
            }

            return {
                ...state,
                showHelp: showHelp
            };
        case SHOW_MESSAGE:
            return {
                ...state,
                message: action.data
            };
        case CLEAR_MESSAGE:
            return {
                ...state,
                message: {
                    message: '',
                    type:    '',
                    timeout: null
                }
            };
        case RESET_TERMINAL:
            return {
                ...initialState,
                apiClient: state.apiClient
            };

        case SET_HOTEL_CONFIG:
            return {
                ...state,
                hotelConfig: action.data
            };
        case LOCATION_CHANGE:
            return {
                ...state,
                showLoader: false
            };
        case SET_INVENTORY:
            return {
                ...state,
                inventory: {
                    lastModified: action.data.lastModified,
                    list:         action.data.inventory,
                    crossSelling: action.data.crossSelling
                }
            };
        case SET_SHOP_FILTER:
            return {
                ...state,
                shop: {
                    ...state.shop,
                    filter:       action.data.filter,
                    activeFilter: action.data.filter[0]
                }
            };
        case SET_SHOP_ACTIVE_FILTER:
            return {
                ...state,
                shop: {
                    ...state.shop,
                    activeFilter: action.data.activeFilter
                }
            };
        case TOGGLE_NO_RESERVATION:
            let noReservation = action.data.noReservation;
            if (noReservation === undefined) {
                noReservation = !state.cart.noReservation;
            }
            return {
                ...state,
                cart: {
                    ...state.cart,
                    noReservation: noReservation
                }
            };
        case ADD_TO_CART:
            cartList.push(action.data.item);

            return {
                ...state,
                cart: {
                    ...state.cart,
                    list: cartList
                }
            };
        case REMOVE_FROM_CART:
            cartList.splice(action.data.key, 1);

            return {
                ...state,
                cart: {
                    ...state.cart,
                    list: cartList
                }
            };
        case CLEAR_CART:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    list:           [],
                    idempotencyKey: 'no-key-generated'
                }
            };
        case GENERATE_IDEMPOTENCY_KEY:
            let idempotencyKey = uuidv4();

            return {
                ...state,
                cart: {
                    ...state.cart,
                    idempotencyKey: idempotencyKey
                }
            };


        default:
            return state;
    }
};
