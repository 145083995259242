const langDe = {
    thisIso:          'de-DE',
    pageTitles:       {
        "default":          "Stayery.",
        "mainPage":         "Cold Drinks,\nWarm Feelings.",
        "shopPage":         {
            "base":        "Spätkauf Bar",
            'hot-drinks':  'Hot drinks',
            'soft-drinks': 'Soft drinks',
            'food':        'Food',
            'meals':       'Meals',
            'snacks':      'Snacks',
            'alcohol':     'Beer & Wine',
            "drinks":      "Drinks",
            "nonfood":     "Other"
        },
        "paymentPage":      {
            "base":    "Sag uns Deine\nApartmentnummer.",
            "waiting": "Bezahle jetzt am\nZahlungsterminal"
        },
        "thankyouPage":     "Thanks!",
        "helppage":         "Brauchst\ndu Hilfe?",
        "verificationCode": "Bitte geben Sie den Verifizierungscode\nfür dieses Gerät ein"
    },
    buttons:          {
        iWantToPay: 'Weiter zur Zahlung',
        clearlist:  "Liste leeren",
        buy:        "Bezahlen",
        cancel:     "Abbrechen"
    },
    start:            {
        "goback": "Zurück"
    },
    main:             {
        shopLable: {
            'hot-drinks':  'Hot drinks',
            'soft-drinks': 'Soft drinks',
            'food':        'Food',
            'meals':       'Meals',
            'snacks':      'Snacks',
            'alcohol':     'Beer & Wine',
            "drinks":      "Drinks",
            "nonfood":     "Other"
        },
        "shop":    "show me more"
    },
    cartlist:         {
        header:   'Deine Goodies',
        total:    'Summe',
        checkbox: 'Mit Rechnung bitte!'
    },
    item: {
        free: 'kostenlos',
        deposit: 'Pfand'
    },
    modal: {
        hotDrinks: {
            title: "SHould I Stay <br/>or ShoulD I GO?",
            description: "RECUP ist Deutschlands größtes Mehrwegsystem für Take-away. Du zahlst 2€ Pfand und kannst den Becher inkl. Deckel bei einem beliebigen Partner deutschlandweit zurückgeben und bekommst Dein Pfandgeld zurück. Bitte beachte, dass STAYERY kein Rückgabepartner ist. <br/>Bei Fragen wende Dich jederzeit gerne an einen Host.",
            cup: "Tasse",
            ownCup: "Eigener Becher"
        },
    },
    payment:          {
        text:    'Du erhältst Deine Rechnung bei Check-Out automatisch per E-Mail.',
        methods: 'Mögliche Zahlungsarten:'
    },
    help:             {
        "text":    "Wenn Du Fragen hast oder es mal Probleme gibt,\nwende Dich einfach an einen unserer Hosts. Falls\nsie gerade nicht vor Ort sind, ruf uns einfach an.",
        "number":  "+49 (0) 30 991 916 510"
    },
    verificationCode: {
        buttons: {
            "submit": "absenden"
        }
    },
    errors:           {
        notFound: "Komisch! Wir finden Dein Apartment leider nicht. Versuch es noch einmal! Sonst ruf' uns an und wir bringen Licht ins Dunkel.",
        general:  "Beim Einkauf ist ein Fehler aufgetreten. Bitte versuche es erneut."
    },
    warning:          {
        "payment-canceled": "Die Zahlung wurde abgebrochen."
    },
    messages:         {
        timeout: "ACHTUNG: Wenn Du noch länger inaktiv bleibst, wirst Du in wenigen Sekunden ausgeloggt.",
        errors:  {
            required:   "Pflichtfelder dürfen natürlich nicht leer sein.",
            errorTitle: "Oooooops, finde den Fehler..."
        }
    },
    fields:           {
        "title":         "Deine privaten Meldedaten",
        "bookingId":     "Buchungsnummer",
        "lastName":      "Nachname",
        "firstName":     "Vorname",
        "birthDate":     "Geburtsdatum",
        "email":         "E-Mail (für Rechnungsversand)",
        "departureDate": "Abreisedatum",
        "address":       "Straße",
        "postalCode":    "PLZ",
        "city":          "Stadt",
        "country":       "Land",
        "roomnumber":    "Apartmentnummer",
        "signature":     "Unterschrift",
        "company":       "Firmenname"
    },
    countries:        {
        AF: "Afghanistan",
        AN: "Niederländische Antillen",
        AL: "Albanien",
        DZ: "Algerien",
        UM: "Amerikanisch-Ozeanien",
        AS: "Amerikanisch-Samoa",
        VI: "Amerikanische Jungferninseln",
        AD: "Andorra",
        AO: "Angola",
        AI: "Anguilla",
        AQ: "Antarktis",
        AG: "Antigua und Barbuda",
        AR: "Argentinien",
        AM: "Armenien",
        AW: "Aruba",
        AZ: "Aserbaidschan",
        AU: "Australien",
        BS: "Bahamas",
        BH: "Bahrain",
        BD: "Bangladesch",
        BB: "Barbados",
        BE: "Belgien",
        BZ: "Belize",
        BJ: "Benin",
        BM: "Bermuda",
        BT: "Bhutan",
        BO: "Bolivien",
        BQ: "Bonaire, Sint Eustatius und Saba",
        BA: "Bosnien und Herzegowina",
        BW: "Botsuana",
        BV: "Bouvetinsel",
        BR: "Brasilien",
        VG: "Britische Jungferninseln",
        IO: "Britisches Territorium im Indischen Ozean",
        BN: "Brunei Darussalam",
        BG: "Bulgarien",
        BF: "Burkina Faso",
        BI: "Burundi",
        CL: "Chile",
        CN: "China",
        CK: "Cookinseln",
        CR: "Costa Rica",
        CW: "Curaçao",
        CD: "Demokratische Republik Kongo",
        KP: "Demokratische Volksrepublik Korea",
        DE: "Deutschland",
        DM: "Dominica",
        DO: "Dominikanische Republik",
        DJ: "Dschibuti",
        DK: "Dänemark",
        EC: "Ecuador",
        SV: "El Salvador",
        CI: "Elfenbeinküste",
        ER: "Eritrea",
        EE: "Estland",
        FK: "Falklandinseln",
        FJ: "Fidschi",
        FI: "Finnland",
        FR: "Frankreich",
        GF: "Französisch-Guayana",
        PF: "Französisch-Polynesien",
        TF: "Französische Süd- und Antarktisgebiete",
        FO: "Färöer",
        GA: "Gabun",
        GM: "Gambia",
        GE: "Georgien",
        GH: "Ghana",
        GI: "Gibraltar",
        GD: "Grenada",
        GR: "Griechenland",
        GB: "Großbritannien",
        GL: "Grönland",
        GP: "Guadeloupe",
        GU: "Guam",
        GT: "Guatemala",
        GG: "Guernsey",
        GN: "Guinea",
        GW: "Guinea-Bissau",
        GY: "Guyana",
        HT: "Haiti",
        HM: "Heard und McDonaldinseln",
        HN: "Honduras",
        HK: "Hong Kong S.A.R., China",
        IN: "Indien",
        ID: "Indonesien",
        IM: "Insel Man",
        IQ: "Irak",
        IR: "Iran",
        IE: "Irland",
        IS: "Island",
        IL: "Israel",
        IT: "Italien",
        JM: "Jamaika",
        JP: "Japan",
        YE: "Jemen",
        JE: "Jersey",
        JO: "Jordanien",
        KY: "Kaimaninseln",
        KH: "Kambodscha",
        CM: "Kamerun",
        CA: "Kanada",
        CV: "Kap Verde",
        KZ: "Kasachstan",
        QA: "Katar",
        KE: "Kenia",
        KG: "Kirgisistan",
        KI: "Kiribati",
        CC: "Kokosinseln (Keeling)",
        CO: "Kolumbien",
        KM: "Komoren",
        CG: "Kongo",
        HR: "Kroatien",
        CU: "Kuba",
        KW: "Kuwait",
        LA: "Laos",
        LS: "Lesotho",
        LV: "Lettland",
        LB: "Libanon",
        LR: "Liberia",
        LY: "Libyen",
        LI: "Liechtenstein",
        LT: "Litauen",
        LU: "Luxemburg",
        MO: "Macau S.A.R., China",
        MG: "Madagaskar",
        MW: "Malawi",
        MY: "Malaysia",
        MV: "Malediven",
        ML: "Mali",
        MT: "Malta",
        MA: "Marokko",
        MH: "Marschallinseln",
        MQ: "Martinique",
        MR: "Mauretanien",
        MU: "Mauritius",
        YT: "Mayotte",
        MK: "Mazedonien",
        MX: "Mexiko",
        FM: "Mikronesien",
        MC: "Monaco",
        MN: "Mongolei",
        ME: "Montenegro",
        MS: "Montserrat",
        MZ: "Mosambik",
        MM: "Myanmar",
        NA: "Namibia",
        NR: "Nauru",
        NP: "Nepal",
        NC: "Neukaledonien",
        NZ: "Neuseeland",
        NI: "Nicaragua",
        NL: "Niederlande",
        NE: "Niger",
        NG: "Nigeria",
        NU: "Niue",
        NF: "Norfolkinsel",
        NO: "Norwegen",
        MP: "Nördliche Marianen",
        OM: "Oman",
        TL: "Osttimor",
        PK: "Pakistan",
        PW: "Palau",
        PS: "Palästina",
        PA: "Panama",
        PG: "Papua-Neuguinea",
        PY: "Paraguay",
        PE: "Peru",
        PH: "Philippinen",
        PN: "Pitcairn",
        PL: "Polen",
        PT: "Portugal",
        PR: "Puerto Rico",
        KR: "Republik Korea",
        MD: "Republik Moldau",
        RE: "Reunion",
        RW: "Ruanda",
        RO: "Rumänien",
        RU: "Russische Föderation",
        BL: "Saint-Barthélemy",
        MF: "Saint-Martin",
        SB: "Salomonen",
        ZM: "Sambia",
        WS: "Samoa",
        SM: "San Marino",
        SA: "Saudi-Arabien",
        SE: "Schweden",
        CH: "Schweiz",
        SN: "Senegal",
        RS: "Serbien",
        SC: "Seychellen",
        SL: "Sierra Leone",
        ZW: "Simbabwe",
        SG: "Singapur",
        SX: "Sint Maarten",
        SK: "Slowakei",
        SI: "Slowenien",
        SO: "Somalia",
        ES: "Spanien",
        LK: "Sri Lanka",
        SH: "St. Helena",
        KN: "St. Kitts und Nevis",
        LC: "St. Lucia",
        PM: "St. Pierre und Miquelon",
        VC: "St. Vinzent und die Grenadinen",
        SD: "Sudan",
        SR: "Suriname",
        SJ: "Svalbard und Jan Mayen",
        SZ: "Swasiland",
        SY: "Syrien",
        ST: "São Tomé und Príncipe",
        ZA: "Südafrika",
        GS: "Südgeorgien und die Südlichen Sandwichinseln",
        SS: "Südsudan",
        TJ: "Tadschikistan",
        TW: "Taiwan",
        TZ: "Tansania",
        TH: "Thailand",
        TG: "Togo",
        TK: "Tokelau",
        TO: "Tonga",
        TT: "Trinidad und Tobago",
        TD: "Tschad",
        CZ: "Tschechische Republik",
        TN: "Tunesien",
        TM: "Turkmenistan",
        TC: "Turks- und Caicosinseln",
        TV: "Tuvalu",
        TR: "Türkei",
        UG: "Uganda",
        UA: "Ukraine",
        HU: "Ungarn",
        UY: "Uruguay",
        UZ: "Usbekistan",
        VU: "Vanuatu",
        VA: "Vatikanstadt",
        VE: "Venezuela",
        AE: "Vereinigte Arabische Emirate",
        US: "Vereinigte Staaten von Amerika",
        VN: "Vietnam",
        WF: "Wallis und Futuna",
        CX: "Weihnachtsinsel",
        BY: "Weißrussland",
        EH: "Westsahara",
        CF: "Zentralafrikanische Republik",
        CY: "Zypern",
        EG: "Ägypten",
        GQ: "Äquatorialguinea",
        ET: "Äthiopien",
        AX: "Ålandinseln",
        AT: "Österreich"
    }
};

export default langDe;
