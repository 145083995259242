import React, {Component}               from 'react';
import Translator                       from "./../../components/Translator";
import * as Sentry                      from '@sentry/react';
import {connect}                        from "react-redux";
import {toggleSpinner, updateInventory} from "../../redux/actions";

class TerminalVerification extends Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            verificationCode: '' // BER-1-TEST'
        };

        this.handleSubmit           = this.handleSubmit.bind(this);
        this.handleChange           = this.handleChange.bind(this);
        this.submitVerificationCode = this.submitVerificationCode.bind(this);
    }

    handleSubmit(event)
    {
        event.preventDefault();
        event.stopPropagation();

        this.submitVerificationCode();
    }

    handleChange(event)
    {
        this.setState({verificationCode: (event.target.value).toUpperCase()});
    }

    submitVerificationCode()
    {
        localStorage.removeItem('verificationCode');

        if (this.state.verificationCode !== '') {
            this.props.toggleSpinner(true, '');

            this.props.apiClient.verifyTerminalCode(this.state.verificationCode).then(response => {
                this.props.toggleSpinner(false, '');
                if (response.error) {
                    return false;
                } else if ((response.success ?? false) === true) {
                    localStorage.setItem('verificationCode', this.state.verificationCode);
                    localStorage.setItem('hotline', response.hotline ?? '');
                    this.props.checkStorageForTerminalCode();
                }

            }).catch((err) => {
                console.warn(err);
                Sentry.captureException(err);
                this.props.toggleSpinner(false, '');
            });
        }
    }

    render()
    {
        return (<div className="overlay overlay-terminalVerification">
            <div className="page-inner-wrapper">
                <div className="content-wrapper">
                    <div className="bodytext-app"><span className="bigger"><Translator
                        content={"pageTitles.verificationCode"}/></span>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <form className="stayery-form" onSubmit={this.handleSubmit}>
                                <div className='form-group'>
                                    <div className="">
                                        <input type="text"
                                               className="form-control"
                                               value={this.state.verificationCode}
                                               onChange={this.handleChange}
                                               autoComplete="off"
                                               placeholder=" "/>
                                        <i className="icon icon-stayery-group hidden"/>
                                    </div>
                                </div>
                                <button
                                    className="btn btn-stayery-black btn-uppercase btn-fullwidth btn-space">
                                    <Translator content={"verificationCode.buttons.submit"}/> <i
                                    className="icon icon-stayery-arrow-r"/></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }

}

export default connect(state => {
    return {
        pathname:  state.router.location.pathname,
        apiClient: state.app.apiClient,
        cart:      state.app.cart
    }
}, {
                           toggleSpinner,
                           updateInventory
                       })(TerminalVerification);
