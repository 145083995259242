import React, {Component} from 'react';
import {connect}          from "react-redux";
import {clearMessage}     from "../../../redux/actions";
import Translator         from "./../../Translator";
import { Player, Controls } from '@lottiefiles/react-lottie-player';

let animationData = require('./Loading-Spinner.json');

class LoadingSpinner extends Component
{
    contentOfSpinner()
    {
        if (this.props.showLoader) {
            return <div className="overlay overlay-loadingspinner">
                <div className="content-wrapper">
                    <div className="absolute-wrapper">
                        {(() => {
                            if (this.props.loaderMessage) {
                                return <Translator component={"div"} content={this.props.loaderMessage}/>;
                            }
                        })()}
                        <Player
                            autoplay
                            loop
                            src={animationData}
                        />
                    </div>
                </div>
            </div>;
        } else {
            return null;
        }
    }

    render()
    {
        return this.contentOfSpinner();
    }
}

export default connect(state => {
    return {
        showLoader:    state.app.showLoader,
        loaderMessage: state.app.loaderMessage
    }
}, {clearMessage})(LoadingSpinner);
