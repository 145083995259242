import React, {Component} from 'react';
import Translator         from "./../../components/Translator";
import {connect}          from "react-redux";
import {clearMessage}     from "../../redux/actions"

class MessageHandler extends Component
{
    constructor(props)
    {
        super(props);
        this.timeout = null;
    }

    componentDidUpdate(prevProps)
    {
        if (this.props.message !== null && this.props.message.message !== '') {
            clearTimeout(this.timeout);
            this.timeout = null;

            if (this.props.message.timeout !== null && this.props.message.timeout > 0) {
                this.timeout = setTimeout(() => {
                    this.props.clearMessage();
                }, this.props.message.timeout);
            }
        }
    }

    renderError()
    {
        return <div className="error-message">
            <h5>
                <i className="icon icon-stayery-exclude"/>
                <Translator content='messages.errors.errorTitle'/>
            </h5>
            <Translator content={this.props.message.message}/>
        </div>;
    }

    renderDefault()
    {
        return <div className={this.props.message.type + "-message"}>
            <i className="icon icon-terminal-exclude"/>
            <Translator content={this.props.message.message}/>
        </div>;
    }

    render()
    {
        if (this.props.message === null || this.props.message.message === '') {
            return null;
        }

        if (this.props.message.type === 'error') {
            return this.renderError();
        }

        return this.renderDefault();
    }
}

export default connect(state => {
    return {
        message: state.app.message
    }
}, {clearMessage})(MessageHandler);
