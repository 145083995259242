import {applyMiddleware, compose, createStore} from 'redux';
import {routerMiddleware}                      from 'connected-react-router';
import {globalAppState as initialState}        from "./state";
import type {ClientConfig}                     from "@stayery/kiosk-terminal-js-client";
import Client                                  from "@stayery/kiosk-terminal-js-client";
import * as Sentry                             from "@sentry/react";
import {createBrowserHistory}                  from 'history';
import createRootReducer                       from './reducer/reducer'
import thunk                                   from 'redux-thunk';
import packageJson                             from '../../package.json';


let rootElement          = document.getElementById('root');
let config: ClientConfig = {
    baseUrl: rootElement.dataset.api,
    version: packageJson.version
};

let apiClient     = new Client(config);
apiClient.onError = function(error) {
    Sentry.captureException(error);
};
window.setInterval(() => {

    apiClient.ping();
}, 60 * 1000 * 5);

let preloadedState = {
    ...initialState,
    app: {
        ...initialState.app,
        apiClient: apiClient
    }
};

export const history = createBrowserHistory();

//@formatter:off
function configureStore(preloadedState) {
    return createStore(
        createRootReducer(history),
        preloadedState,
        compose(
            applyMiddleware(
                routerMiddleware(history),
                thunk,
            ),
        ),
    );
}
//@formatter:on

export default configureStore(preloadedState);
