import {combineReducers}   from "redux";
import {appReducer}        from "./appReducer";
import {apaleoReducer}     from "./apaleoReducer";
import {connectRouter}     from 'connected-react-router'

const createRootReducer = (history) => combineReducers({
                                                           router:     connectRouter(history),
                                                           app:        appReducer,
                                                           apaleo:     apaleoReducer
                                                       });

export default createRootReducer;
