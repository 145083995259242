import React, {Component} from 'react';
import {Route, Switch}    from 'react-router-dom';
import {ConnectedRouter}  from 'connected-react-router';
import Start              from "../pages/Start/Start";
import Main               from "../pages/Main/Main";
import Help               from "../pages/Help/Help";
import {history}          from "../redux/store";
import Shop               from "../pages/Shop/Shop";
import Payment            from "../pages/Shop/Payment";
import Confirm            from "../pages/Shop/Confirm";
import {withRouter}       from "react-router";

class AppRoutes extends Component
{
    render()
    {
        return (<ConnectedRouter history={history}>
            <Switch>
                <Route path='/' exact component={withRouter(Start)}/>
                <Route path='/:locale/' exact component={withRouter(Main)}/>
                <Route path='/:locale/shop/shop' exact component={withRouter(Shop)}/>
                <Route path='/:locale/shop/payment' exact component={withRouter(Payment)}/>
                <Route path='/:locale/shop/confirm' exact component={withRouter(Confirm)}/>
                <Route path='/:locale/help' exact component={withRouter(Help)}/>
            </Switch>
        </ConnectedRouter>);
    }
}

export default AppRoutes;
