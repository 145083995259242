// @flow
import React, {Component}                                                 from 'react';
import {connect}                                                          from "react-redux";
import {addToCart, setShopActiveFilter, showWarning, toggleNoReservation} from "../redux/actions";
import type {CartItem}                                                    from "../Types/Types";
import Translator                                                         from "./Translator";
import ScrollArea                                                         from 'react-scrollbar';
import { Item } from "./Item";
import {ItemModifierModal} from "./ItemModifierModal";

const freeContainerItems = [
    {
        id: '',
        currency: '',
        deleted: '',
        hidden: '',
        image: process.env.PUBLIC_URL + "/images/cup_v2_430x325.png",
        price: 0,
        priority: 0,
        productId: '',
        storageStock: 0,
        subAccount: "KIOSKOTHER",
        type: "nonfood",
        vatType: "Null",
        translations: [
            {
                id: 52,
                langParent: 31,
                language: "de",
                name: "Tasse"
            },
            {
                id: 52,
                langParent: 31,
                language: "en",
                name: "Cup"
            }
        ],
    },
    {
        id: '',
        currency: '',
        deleted: '',
        hidden: '',
        image: process.env.PUBLIC_URL + '/images/ownCup_430x325.png',
        price: 0,
        priority: 0,
        productId: '',
        storageStock: 0,
        subAccount: "KIOSKOTHER",
        type: "nonfood",
        vatType: "Null",
        translations: [
            {
                id: 52,
                langParent: 31,
                language: "de",
                name: "Eigener Becher"
            },
            {
                id: 52,
                langParent: 31,
                language: "en",
                name: "Own Cup"
            }
        ],
    }
]

class Inventory extends Component
{
    constructor(props)
    {
        super(props);
        this.state           = {
            listLongerThanContainer: false,
            scrollPosition:          1,
            modifierModalOpen:       false,
            mainItem: null
        }
        this.scrollArea      = React.createRef();
        this.handleAddToCart = this.handleAddToCart.bind(this);
    }

    openModal = (item) => this.setState({ modifierModalOpen: true, mainItem: item });
    closeModal = () => this.setState({ modifierModalOpen: false, mainItem: null });

    handleAddToCart(item)
    {
        let cartItem: CartItem = {
            translations: item.translations,
            productId:    item.productId,
            id:           item.id,
            price:        item.price,
            currency:     item.currency,
            image:        item.image,
            vatType:      item.vatType
        };
        this.props.addToCart(cartItem);
        this.props.toggleNoReservation(true)
    }

    render()
    {
        let theInventory = this;

        let list = this.props.inventory.list.sort(function(a, b) {
            let nameA = this.getTranslationForItem(a);
            let nameB = this.getTranslationForItem(b);

            if (parseInt(a.priority) < parseInt(b.priority)) {
                return true;
            }

            if (parseInt(a.priority) === parseInt(b.priority)) {
                return nameA.localeCompare(nameB);
            }
        }.bind(this));

        let classNameScrollArea = "area";
        const filteredList = this.buildList(list, theInventory);

        if (this.state.listLongerThanContainer) {
            classNameScrollArea += " _listLongerThanContainer";
        }

        switch (this.state.scrollPosition) {
            case -1:
                classNameScrollArea += " _showUpArrow";
                break;
            case 0:
                classNameScrollArea += " _showUpArrow _showDownArrow";
                break;
            case 1:
                classNameScrollArea += " _showDownArrow";
                break;
            default:
                break;
        }

        const scrollArea = <ScrollArea
            ref={this.scrollArea}
            className={classNameScrollArea}
            contentClassName="content"
            horizontal={false}
            onScroll={this.onScroll}
            smoothScrolling={true}
        >
            <ul className="__list">
                {filteredList}
            </ul>
        </ScrollArea>;
        return (
            <div className="inventory-list">
                <div className="__header-wrap">
                    {this.props.shop.filter.map((item, key) => {
                        if (item !== '') {
                            let className = "__header";

                            if (this.props.shop.filter.length > 1 && this.props.shop.activeFilter === item) {
                                className += " _active";
                            }

                            return <Translator
                                key={key}
                                className={className}
                                component="div"
                                onClick={() => {
                                    this.props.setShopActiveFilter(item);
                                    this.setState({scrollPosition: 1});
                                    this.scrollArea.current.setState({topPosition:0});
                                }}
                                content={'pageTitles.shopPage.' + item}
                            />
                        } else {
                            return ''
                        }
                    })}
                </div>
                {scrollArea}
                {/*<i className={'__scroll-up icon icon-stayery-arrow-d'}/>*/}
                <i className={'__scroll-down icon icon-stayery-arrow-d'}/>
                <ItemModifierModal
                    open={this.state.modifierModalOpen}
                    closeModal={this.closeModal}
                    items={[...list.filter(item => item.productId === this.props.inventory.crossSelling['hot-drinks']), ...freeContainerItems]}
                    mainItem={this.state.mainItem}
                    getTranslationForItem={(item) => this.getTranslationForItem(item)}
                    onSelect={theInventory.handleAddToCart}
                    priceAsDeposit
                    title={<Translator content={"modal.hotDrinks.title"} />}
                    description={<Translator content={"modal.hotDrinks.description"} />}
                />
            </div>
        );
    }

    buildList(list, theInventory)
    {
        const map = list.map((item, key) => {
            if (item.type === this.props.shop.activeFilter
                && item.crossSelling === '') {
                let name = this.getTranslationForItem(item);

                return (
                    <Item
                        key={key}
                        item={item}
                        name={name}
                        onClick={() => {
                            //todo get flag from item itself
                            if(item.type === 'hot-drinks') {
                                this.openModal(item);
                            } else {
                                theInventory.handleAddToCart(item);
                            }
                        }}
                    />
                );
            } else {
                return null;
            }
        });
        return map.filter(elem => elem !== null);
    }

    onScroll = (value) => {
        console.log(value);
        let containerHeight = value.containerHeight;
        let topPosition     = value.topPosition;
        let realHeight      = value.realHeight;

        let listLongerThanContainer = realHeight > containerHeight;
        let scrollPosition          = -1;
        let threshold               = 100;

        if (this.state.scrollPosition !== -1) {
            if (topPosition) {
                let b = containerHeight + topPosition < realHeight - threshold;
                if (topPosition > threshold && b) {
                    scrollPosition = 0;
                } else if (b) {
                    scrollPosition = 1;
                } else {
                    scrollPosition = -1;
                }
            } else {
                scrollPosition = 1;
            }
        }

        this.setState({
                          listLongerThanContainer: listLongerThanContainer,
                          scrollPosition:          scrollPosition
                      });
    }

    getTranslationForItem(item)
    {
        let translationString = '';

        item.translations.map((translation) => {
            if (translation.language === this.props.locale) {
                translationString = translation.name;
            }
            return '';
        });

        return translationString;
    }
}

export default connect(state => {
    return {
        inventory: state.app.inventory,
        shop:      state.app.shop,
        locale:    state.app.locale
    }
}, {
                           showWarning,
                           addToCart,
                           toggleNoReservation,
                           setShopActiveFilter
                       })(Inventory);

