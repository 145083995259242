import React, { FC } from 'react';
import Modal from 'react-modal';
import {Item} from "./Item";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement(document.getElementById('root'));

export type ItemModifierModalProps = {
    open: boolean,
    closeModal: () => void,
    onSelect: (item) => void,
    items: any[],
    mainItem: any,
    getTranslationForItem: () => string,
    priceAsDeposit: boolean,
    title: string | JSX.Element,
    description: string | JSX.Element
}

export const ItemModifierModal: FC<ItemModifierModalProps> = (props) => {
    const {
        open,
        closeModal,
        onSelect,
        mainItem,
        items,
        getTranslationForItem,
        priceAsDeposit = false,
        title,
        description
    } = props;

    const handleSelect = (item) => {
        onSelect(mainItem);

        console.log('item', mainItem);
        if (item.price && item.price > 0) {
            onSelect(item);
        }

        closeModal();
    };

    return (
        <Modal
            isOpen={open}
            overlayClassName="modal-overlay"
            className="modal-content"
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={false}
        >
            <div
                className="item-modifier-modal"
            >
                <div
                    className="__header"
                >
                    <h1 className="header-title">
                        {title}
                    </h1>
                    <button
                        className="modal-btn-close"
                        onClick={() => {
                            closeModal && closeModal();
                        }}
                    >
                        <span><i className="icon icon-stayery-closer"/></span>
                    </button>
                </div>
                <div
                    className="modal-list"
                >
                    <div
                        className="__list"
                    >
                        {items.map((item, index) => {
                            let name = getTranslationForItem(item);

                            return(
                                <Item
                                    key={index}
                                    item={item}
                                    name={name}
                                    priceAsDeposit={priceAsDeposit}
                                    onClick={handleSelect}
                                />
                            );
                        })}
                    </div>
                    <div
                        className="__description"
                    >
                        {description}
                    </div>
                </div>
            </div>
        </Modal>
    );
};
