// APALEO
export const ON_LOAD_RESERVATION_SUCCESS = 'ON_LOAD_RESERVATION_SUCCESS';
export const ON_LOAD_RESERVATION_ERROR   = 'ON_LOAD_RESERVATION_ERROR';
export const ON_UPDATE_SIGNED_GUESTS     = 'ON_UPDATE_SIGNED_GUESTS';
export const ON_CHECKIN_ERROR            = "ON_CHECKIN_SUCCESS";
export const ON_CHECKIN_SUCCESS          = "ON_CHECKIN_ERROR";
export const TOGGLE_SPINNER              = 'TOGGLE_SPINNER';
export const TOGGLE_HELP                 = 'TOGGLE_HELP';
export const SET_HOTEL_CONFIG            = 'SET_HOTEL_CONFIG';

// App
export const RESET_TERMINAL = "RESET_TERMINAL";
export const SHOW_MESSAGE   = "SHOW_MESSAGE";
export const CLEAR_MESSAGE  = "CLEAR_MESSAGE";

export const DEFAULT_MESSAGE_TIMEOUT = 8000;

export const SET_SHOP_FILTER          = 'SET_CART_FILTER';
export const SET_SHOP_ACTIVE_FILTER   = 'SET_SHOP_ACTIVE_FILTER';
export const GENERATE_IDEMPOTENCY_KEY = 'GENERATE_IDEMPOTENCY_KEY';
export const ADD_TO_CART              = 'ADD_TO_CART';
export const REMOVE_FROM_CART         = 'REMOVE_FROM_CART';
export const CLEAR_CART               = 'CLEAR_CART';
export const SET_INVENTORY            = 'SET_INVENTORY';
export const TOGGLE_NO_RESERVATION    = 'TOGGLE_NO_RESERVATION';
export const CHANGE_LOCALE            = 'CHANGE_LOCALE';
