// @flow
import React, {Component}                                                       from 'react';
import {connect}                                                                from "react-redux";
import {clearCart, generateIdempotencyKey, removeFromCart, toggleNoReservation} from "../redux/actions";
import Currency                                                                 from "./Currency";
import PropTypes                                                                from "prop-types";
import {goToPaymentPage}                                                        from "../pages/Types/Dispatcher";
import Translator                                                               from "./Translator";
import {animateScroll}                                                          from "react-scroll";

let translate = require('counterpart');

class CartList extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            locale: translate.getLocale()
        };

        this.handleRemoveFromCart = this.handleRemoveFromCart.bind(this);
        this.handleClearCart      = this.handleClearCart.bind(this);
        this.handleBuy            = this.handleBuy.bind(this);
        this.localeChanged        = this.localeChanged.bind(this);

        translate.onLocaleChange(this.localeChanged);
    }

    componentDidMount()
    {
        if (this.props.cart.list.length > 2) {
            this.scrollToBottom();
        }
    }

    componentDidUpdate()
    {
        if (this.props.cart.list.length > 2) {
            this.scrollToBottom();
        }
    }

    scrollToBottom()
    {
        animateScroll.scrollToBottom({
                                         containerId: "CartList__Content"
                                     });
    }

    handleBuy()
    {
        if (this.props.cart.list.length > 0) {
            this.props.generateIdempotencyKey();
            goToPaymentPage('Shop');
        }
    }

    handleRemoveFromCart(productId)
    {
        if (this.props.clickable) {
            let index = -1;
            this.props.cart.list.map((item, key) => {
                if (item.productId === productId) {
                    index = key;
                }
                return '';
            })
            if (index >= 0) {
                this.props.removeFromCart(index);
            }
        }
    }

    handleClearCart()
    {
        if (this.props.clickable) {
            this.props.clearCart();
        }
    }

    localeChanged(newLocale, oldLocale)
    {
        this.setState({locale: newLocale});
    }

    render()
    {
        let theCart   = this;
        let summe     = 0;
        let className = "cart-list";
        if (theCart.props.clickable) {
            className += ' _clickable';
        }
        let list = this.props.cart.list;

        return (<div className="cart-list-container">
            <div className={className}>
                <div className={'__header'}>
                    <Translator content="cartlist.header"/>&nbsp;({this.props.cart.list.length})
                </div>
                <div className="__content" id="CartList__Content">
                    <ul className="__list">
                        {list.map((item, key) => {
                            summe += item.price;

                            let name = '';
                            for (let i = 0; i < item.translations.length; i++) {
                                let translation = item.translations[i];
                                if (translation.language === this.state.locale) {
                                    name = translation.name;
                                }
                            }

                            return <li className="kiosk-item" key={key}>
                                <div className="__close" onClick={function() {
                                    theCart.handleRemoveFromCart(item.productId);
                                }}>
                                    <i className="icon icon-stayery-closer"></i>
                                </div>
                                <div className="__image">
                                    <img src={item.image} alt={name}/>
                                </div>
                                <div className="__name">{name}</div>
                                <div className="__price"><Currency quantity={item.price} currency={item.currency}/>
                                </div>
                            </li>
                        })}
                    </ul>
                </div>
                <div className="__footer">
                    <div className="__total">
                        <div className="__name"><Translator content={'cartlist.total'}/></div>
                        <div className="__price"><Currency quantity={summe} currency={'EUR'}/></div>
                    </div>
                    {(() => {
                        if (theCart.props.clickable) {
                            return <div className="__controls">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <div className="cartlist-checkbox">
                                                <input type="checkbox" className="custom-control-input"
                                                       id="noReservationCheck"
                                                       onChange={() => {
                                                           this.props.toggleNoReservation();
                                                       }}
                                                       checked={!this.props.cart.noReservation}/>
                                                <label className="custom-control-label" htmlFor="noReservationCheck">
                                                    <Translator content={'cartlist.checkbox'}/>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-stayery-black btn-uppercase btn-fullwidth btn-space"
                                                onClick={this.handleBuy}
                                        >
                                            <Translator content={"buttons.buy"}/>
                                            <i className="icon icon-stayery-arrow-r"/>
                                        </button>
                                    </div>
                                    {/*<div className="col-12">*/}
                                    {/*    <button className="btn btn-link btn-uppercase btn-fullwidth btn-space"*/}
                                    {/*            onClick={this.handleClearCart}*/}
                                    {/*    >*/}
                                    {/*        <Translator content={"buttons.clearlist"}/>*/}
                                    {/*        <i className="icon icon-stayery-closer"/>*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        }
                    })()}
                </div>
            </div>
        </div>);
    }
}

// Definition of LabeledInput component properties
CartList.propTypes = {clickable: PropTypes.bool};

// Definition of default LabeledInput component properties
CartList.defaultProps = {clickable: true};


export default connect(state => {
    return {cart: state.app.cart}
}, {
                           clearCart,
                           removeFromCart,
                           generateIdempotencyKey,
                           toggleNoReservation
                       })(CartList);

