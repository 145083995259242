import {globalAppState as initialState} from "../state";
import {
    ON_LOAD_RESERVATION_SUCCESS,
    ON_LOAD_RESERVATION_ERROR,
    RESET_TERMINAL,
    ON_CHECKIN_SUCCESS,
    ON_CHECKIN_ERROR,
    ON_UPDATE_SIGNED_GUESTS
}                                       from "../../constants/constants";

export const apaleoReducer = (state = initialState.apaleo, action) => {
    switch (action.type) {
        case ON_LOAD_RESERVATION_SUCCESS:
            return {
                ...state,
                guestCredentials: action.data.guestCredentials,
                reservation:      action.data.reservation
            };
        case ON_LOAD_RESERVATION_ERROR:
            return {
                ...state
            };
        case ON_CHECKIN_SUCCESS:
            return {
                ...state,
                roominformations: action.data.roominformations
            };
        case ON_CHECKIN_ERROR:
            return {
                ...state
            };
        case ON_UPDATE_SIGNED_GUESTS:
            return {
                ...state,
                signedGuests: action.signedGuests
            };

        case RESET_TERMINAL:
            return initialState;

        default:
            return state;
    }
};
