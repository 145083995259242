import React, {Component} from 'react';
import Translator         from "./../../components/Translator";
import Header             from "../Header/Header";

import softdrinksImage                from '../../styles/images/softdrinks.jpg';
import nonfoodImage                   from '../../styles/images/nonfood.jpg';
import snacksImage                    from '../../styles/images/snacks.jpg';
import {connect}                      from "react-redux";
import {setShopFilter, toggleSpinner} from "../../redux/actions";
import {goToShopPage}                 from "../Types/Dispatcher";

class Main extends Component
{
    render()
    {


        return (<div className="page page-main">
            <div className="page-inner-wrapper">
                <Header
                    headerTitle="pageTitles.mainPage"
                />
                <div className="content-wrapper">
                    <div className="container">
                        <div className="flipkachel-wrapper">
                            <div className="stayery-flipkachel _drinks" onClick={() => {
                                this.props.setShopFilter(['soft-drinks', 'hot-drinks', 'alcohol']);
                                goToShopPage('Main');
                            }}>
                                <div className="flipkachel-inner-wrap">
                                    <div className="__header"><Translator
                                        content={"main.shopLable.drinks"}/></div>
                                    <div className="__image">
                                        <img src={softdrinksImage} alt="Check-in"/>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="__left">
                                                <Translator content={"main.shop"}/>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="__right">
                                                <i className="icon icon-stayery-arrow-r"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="stayery-flipkachel _food" onClick={() => {
                                this.props.setShopFilter(['snacks', 'meals']);
                                goToShopPage('Main')
                            }}>
                                <div className="flipkachel-inner-wrap">
                                    <div className="__header"><Translator
                                        content={"main.shopLable.food"}/></div>
                                    <div className="__image">
                                        <img src={snacksImage} alt="Check-in"/>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="__left">
                                                <Translator content={"main.shop"}/>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="__right">
                                                <i className="icon icon-stayery-arrow-r"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="stayery-flipkachel _nonfood" onClick={() => {
                                this.props.setShopFilter(['nonfood']);
                                goToShopPage('Main')
                            }}>
                                <div className="flipkachel-inner-wrap">
                                    <div className="__header"><Translator
                                        content={"main.shopLable.nonfood"}/></div>
                                    <div className="__image">
                                        <img src={nonfoodImage} alt="Check-in"/>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="__left">
                                                <Translator content={"main.shop"}/>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="__right">
                                                <i className="icon icon-stayery-arrow-r"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default connect(state => {
    return {
        pathname:  state.router.location.pathname,
        apiClient: state.app.apiClient,
        cart:      state.app.cart
    }
}, {
                           toggleSpinner,
                           setShopFilter
                       })(Main);
