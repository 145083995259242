// @flow
import React, {Component}         from 'react';
import Header                     from "../Header/Header";
import {connect}                  from "react-redux";
import {addToCart, toggleSpinner} from "../../redux/actions";
import Inventory                  from "../../components/Inventory";
import {goToMainPage}             from "../Types/Dispatcher";

class Shop extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            booking: {
                bookingId: '',
                lastName:  ''
            }
        };
    }

    render()
    {
        if (this.props.inventory.list.length === 0) {
            goToMainPage('Shop');
        }
        return (<div className={"page page-shop"}>
            <div className="page-inner-wrapper">
                <Header backButtonVisible={true}/>

                <div className="content-wrapper">
                    <div className="container">
                        <Inventory/>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default connect(state => {
    return {
        pathname:  state.router.location.pathname,
        apiClient: state.app.apiClient,
        cart:      state.app.cart,
        inventory: state.app.inventory,
        shop:      state.app.shop
    }
}, {
                           toggleSpinner,
                           addToCart
                       })(Shop);

