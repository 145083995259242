export const globalAppState = {
    apaleo: {
        // guestCredentials: {},
        // reservation:      {},
        // signedGuests:     []
    },
    app:    {
        // hotelConfig: {},
        locale:        'de',
        message:       {
            message: '',
            type:    '',
            timeout: null
        },
        showLoader:    false,
        showHelp:      false,
        loaderMessage: "",
        apiClient:     null,
        inventory:     {
            lastModified: 0,
            list:         []
        },
        shop:          {
            filter:       [''],
            activeFilter: ''
        },
        cart:          {
            list:           [],
            idempotencyKey: 'no-key-generated',
            noReservation:  true
        }
    }
};
