import React, {Component}   from 'react';
import {connect}            from "react-redux";
import PropTypes            from 'prop-types';
import packageJson          from '../package.json';

import './App.css';
import TerminalVerification from "./pages/TerminalVerification/TerminalVerification";

import * as Sentry from '@sentry/react';

import {isSafari, isTablet} from "react-device-detect";
import {
    changeLocale, clearCart, clearMessage, setShopFilter, showWarning, toggleSpinner, updateInventory
}                           from "./redux/actions";
import AppRoutes            from "./routings/AppRoutes";
import LoadingSpinner       from "./components/feedback/LoadingSpinner/LoadingSpinner";
import MessageHandler       from "./components/feedback/MessageHandler";
import {goToStartPage}      from "./pages/Types/Dispatcher";
import CartList             from "./components/CartList";
import Help                 from "./pages/Help/Help";

let translate = require('counterpart');

class App extends Component
{

    timeoutIDRedirect;
    timeoutIDMessage;

    static contextTypes = {
        router: PropTypes.object.isRequired
    };

    constructor(props)
    {
        super(props);

        this.state = {
            updateRequired:               false,
            terminalIsInactive:           false,
            showTerminalVerificationPage: false
        };

        this.startTimer                  = this.startTimer.bind(this);
        this.onUserActivity              = this.onUserActivity.bind(this);
        this.goInactive                  = this.goInactive.bind(this);
        this.showInactivityMessage       = this.showInactivityMessage.bind(this);
        this.checkStorageForTerminalCode = this.checkStorageForTerminalCode.bind(this);
        this.checkForInventoryUpdates    = this.checkForInventoryUpdates.bind(this);
        this.isHomeScreen                = this.isHomeScreen.bind(this);

        translate.onLocaleChange(function(newLocale, oldLocale) {
            this.props.changeLocale(newLocale);
        }.bind(this));
    }

    componentWillReceiveProps()
    {
        this.resetDefaultStates();
    }

    componentDidUpdate()
    {
        let pathname = this.context.router.history.location.pathname;
        if ((pathname === "/" || pathname === "/main") && this.state.updateRequired) {
            this.appUpdate();
        }
        // trigger timeout reset on componentDidUpdate to cover route changes.
        this.onUserActivity();
    }

    componentDidMount()
    {
        this.checkStorageForTerminalCode();
        let app = this;
        window.addEventListener('mousedown', this.onUserActivity, false);
        let pathname = this.context.router.history.location.pathname;

        this.props.apiClient.setEventListener('terminalCodeInvalid', function() {
            console.log('App:componentDidMount:terminalCodeInvalid:start');
            localStorage.removeItem('verificationCode');
            app.checkStorageForTerminalCode();
        }.bind(this));

        this.props.apiClient.setEventListener('updateRequired', function() {
            console.log('App:componentDidMount:updateRequired:start');
            if (!this.state.updateRequired) {
                app.setState({updateRequired: true});

                console.log('App:componentDidMount:updateRequired:compare', pathname);
                if (pathname === "/" || pathname === "/main" || pathname === "/main/") {
                    app.appUpdate();
                }
            }
        }.bind(this));

        this.checkForInventoryUpdates();
        setInterval(this.checkForInventoryUpdates, 60000 * 5);
        this.startTimer();
    }

    appUpdate = () => {
        console.log('App:appUpdate');
        window.location.reload(true);
    };

    componentDidCatch(error, errorInfo)
    {
        this.setState({error});
        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    resetDefaultStates()
    {
        this.props.clearMessage();
    }

    startTimer()
    {
        console.log('App:startTimer:start');
        this.resetDefaultStates();
        if (!this.isHomeScreen()) {
            console.log('App:startTimer:init-timer');
            this.timeoutIDRedirect = window.setTimeout(this.goInactive, 90000);
            this.timeoutIDMessage  = window.setTimeout(this.showInactivityMessage, 75000);
        }
    }

    isHomeScreen()
    {
        console.log('App:isHomeScreen:start');
        let pathname = this.props.pathname;
        const b      = pathname === "/";
        console.log('App:isHomeScreen:end', b);
        return b;
    };

    onUserActivity()
    {
        window.clearTimeout(this.timeoutIDRedirect);
        window.clearTimeout(this.timeoutIDMessage);
        this.startTimer();
    }

    goInactive()
    {
        this.props.clearCart();
        this.props.setShopFilter(['']);
        goToStartPage();
    }

    showInactivityMessage()
    {
        this.props.showWarning('messages.timeout', null)
    }


    checkForInventoryUpdates()
    {
        console.log('App:checkForInventoryUpdates:start');
        if (this.isHomeScreen()) {
            this.updateInventory();
        }
    }

    updateInventory = () => {
        console.log('App:updateInventory:start');
        this.props.updateInventory().then(response => {
            console.log('App:updateInventory:then', response);
        }).catch(err => {
            console.log('App:updateInventory:err');
            Sentry.captureException(err);
        });
    }

    checkStorageForTerminalCode()
    {
        let terminalCode = localStorage.getItem('verificationCode');

        if (terminalCode) {
            Sentry.setTag("terminalCode", terminalCode);
            this.props.apiClient.setTerminalCode(terminalCode);
            this.setState({showTerminalVerificationPage: false});
            this.updateInventory();
        } else {
            this.setState({showTerminalVerificationPage: true});
        }
    }

    render()
    {
        if (isSafari) {
            document.documentElement.classList.add('isSafari')
        }
        if (isTablet) {
            document.documentElement.classList.add('isTablet')
        }


        let pathname = this.props.pathname;
        let showCart = pathname === "/" || pathname === "/main" || pathname === "/main/" || pathname ===
            "/main/shop/shop/";

        let showCartCss = this.props.cart.list.length > 0 && showCart ? ' _show-cart' : '';

        return (<div className="App">
            {this.state.showTerminalVerificationPage ? (<TerminalVerification
                apiClient={this.props.apiClient}
                checkStorageForTerminalCode={this.checkStorageForTerminalCode}
            />) : false}
            <MessageHandler/>
            <LoadingSpinner/>

            <div className={"shop-wrapper" + showCartCss}>
                <AppRoutes/>
                <CartList/>
                <Help/>
                <div style={{
                    position:   'absolute',
                    right:      0,
                    bottom:     0,
                    opacity:    0.1,
                    userSelect: "none"
                }}>{packageJson.version}</div>
            </div>

        </div>);
    }
}

export default connect(state => {
    return {
        pathname:  state.router.location.pathname,
        apiClient: state.app.apiClient,
        cart:      state.app.cart
    }
}, {
                           clearCart,
                           showWarning,
                           clearMessage,
                           updateInventory,
                           toggleSpinner,
                           setShopFilter,
                           changeLocale
                       })(App);
