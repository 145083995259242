// @flow
import React, {Component}                      from 'react';
import Header                                  from "../Header/Header";
import {connect}                               from "react-redux";
import {generateIdempotencyKey, toggleSpinner} from "../../redux/actions";
import {Link}                                  from "react-router-dom";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import {goToMainPage}                          from "../Types/Dispatcher";


let animationData = require('./Thank_You_Animation.json');

class Confirm extends Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            redirect: false
        };
    }

    render()
    {
        if (this.state.redirect) {
            goToMainPage('Confirm');
        }

        return (<div className="page page-thankyou">
            <div className="page-inner-wrapper">
                <Header
                    headerTitle='pageTitles.thankyouPage'
                    deactivateLanguageSwitcher={true}
                />
                <div className="content-wrapper">
                    <div className="__animation">
                        <Link to="/">
                            <Player
                                autoplay
                                loop
                                src={animationData}
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default connect(state => {
    return {
        pathname:  state.router.location.pathname,
        apiClient: state.app.apiClient,
        cart:      state.app.cart
    }
}, {
                           toggleSpinner,
                           generateIdempotencyKey
                       })(Confirm);

