// @flow
import React, {Component} from 'react';
import Translator         from "./../Translator";
import PropTypes          from 'prop-types';

class FormInputTextWithLabel extends Component
{
    constructor(props)
    {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event)
    {
        event.preventDefault();
        event.stopPropagation();
        // Propagate value change upwards
        this.props.onChange(event);
    }

    render()
    {
        let requiredClass     = "control-label " + (this.props.required ? 'required' : 'optional');
        let className         = "form-control";
        let classNameReadOnly = this.props.readOnly ? 'readonly disabled' : '';
        let errorMessage      = null;

        let label = <span>&nbsp;</span>;
        if (this.props.label !== '') {
            label = <Translator content={this.props.label}/>;
        }

        return (<div className='form-group'>
            <label className={requiredClass}>
                {label}
            </label>
            <div className={classNameReadOnly}>
                <input type={this.props.type}
                       className={className}
                       value={this.props.value}
                       name={this.props.name}
                       title={errorMessage}
                       required={this.props.required}
                       readOnly={this.props.readOnly}
                       disabled={this.props.readOnly}
                       onChange={this.handleChange}
                       autoComplete="off"
                       placeholder=" "/>
                <i className="icon icon-stayery-group hidden"/>
            </div>
        </div>);
    }
}


// Definition of LabeledInput component properties
FormInputTextWithLabel.propTypes = {
    label:    PropTypes.string, // Required input label
    name:     PropTypes.string.isRequired, // Required name (as identifier)
    onChange: PropTypes.func, // Optional on value change function (to update global state)
    readOnly: PropTypes.bool, // Optional read only modifier
    required: PropTypes.bool, // Optional read only modifier
    type:     PropTypes.string, // Optional type of the input
    value:    PropTypes.string // Optional default input value
};

// Definition of default LabeledInput component properties
FormInputTextWithLabel.defaultProps = {
    label:    "",
    value:    "",
    name:     "",
    type:     "text",
    readOnly: false,
    required: false,
    onChange: (event) => {
    }
};

export default FormInputTextWithLabel;

