import React, {Component} from 'react';
import {Link}             from 'react-router-dom';
import Header             from "../Header/Header";
import { Player, Controls } from '@lottiefiles/react-lottie-player';

let animationData = require('./Intro_Stayery_Kiosk.json');

class Start extends Component
{

    render()
    {
        // // go directly to main and dont show spinner
        // return (<Redirect refresh={true} to='/main'/>);

        return (<div className="page page-home">
            <Player
                autoplay
                loop
                src={animationData}
            />
            <div className="page-inner-wrapper">
                <Header
                    headerTitle='pageTitles.default'
                />
                <Link className="content-wrapper" to="/main/">
                    <div className="btn btn-stayery-black btn-uppercase btn-fullwidth btn-space">
                        Tap to Start <i className="icon-stayery-arrow-r"> </i>
                    </div>
                </Link>
            </div>
        </div>);
    }
}

export default Start;
