import langEn from "../../languages/en";
import langDe from "../../languages/de";

import PropTypes          from 'prop-types';
import React, {Component} from "react";
import Translator         from "./../../components/Translator";
import {connect}          from "react-redux";
import {toggleHelp}       from "../../redux/actions";
import i18next            from "i18next";

let counterpart = require('counterpart');
counterpart.registerTranslations('en', langEn);
counterpart.registerTranslations('de', langDe);

class Header extends Component
{

    static contextTypes = {
        router: PropTypes.object.isRequired
    };

    constructor(props)
    {
        super(props);

        this.changeHelpViewState = this.changeHelpViewState.bind(this);
    }

    languageMenu()
    {
        const lang = counterpart.getLocale();
        return <div className="navbar-language">
            <ul className="language-menu">
                <li className={lang === 'de' ? 'active' : ''}>
                    <span onClick={(e) => this.changeLanguage(e, 'de')}>
                        <span>DE</span>
                    </span>
                </li>
                <li className="__spacer">/</li>
                <li className={lang === 'en' ? 'active' : ''}>
                    <span onClick={(e) => this.changeLanguage(e, 'en')}>
                        <span>EN</span>
                    </span>
                </li>
                {(this.props.showHelp) ? (<li className="__help">
                    <div onClick={this.changeHelpViewState}>
                        <span><i className="icon icon-stayery-closer"/></span>
                    </div>
                </li>) : (<li onClick={this.changeHelpViewState} className="__help">
                    <i className="icon icon-stayery-help"/>
                </li>)}
            </ul>
        </div>
    }

    backButton()
    {
        return !this.props.showHelp ? (<div className="back-button"
                                            hidden={!this.props.backButtonVisible}
                                            onClick={this.context.router.history.goBack}>
            <i className="icon icon-stayery-arrow-l"/><Translator content={"start.goback"}/>
        </div>) : false;
    }

    changeLanguage(e, lang)
    {
        e.preventDefault();
        counterpart.setLocale(lang);
        i18next.changeLanguage(lang);
        this.setState({lang: lang});
    }

    changeHelpViewState()
    {
        this.props.toggleHelp(!this.props.showHelp);
    }

    render()
    {
        return (<header className={"app-header" + (this.props.headerTitle !== '' ? ' _has-header' : '') +
        (this.props.backButtonVisible ? ' _has-back' : '')}>
            <div className="container">
                <div className="navigation-wrapper">
                    <div className="navigation-left">
                        {this.backButton()}
                        <h1 className="header-title" hidden={this.props.headerTitle === ''}>
                            <Translator content={this.props.headerTitle}/>
                        </h1>
                    </div>
                    {(!this.props.deactivateLanguageSwitcher) ? (
                        <div className="navigation-right">{this.languageMenu()}</div>) : false}
                </div>
            </div>
        </header>);
    }
}

// Definition of LabeledInput component properties
Header.propTypes = {
    headerTitle: PropTypes.string
};

// Definition of default LabeledInput component properties
Header.defaultProps = {
    headerTitle: ''
};


export default connect(state => {
    return {
        showHelp: state.app.showHelp
    }
}, {toggleHelp})(Header);
