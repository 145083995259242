import {history} from "../../redux/store";

// routing

export const goToStartPage = (referer?: string) => {
    history.push({
                     pathname: process.env.PUBLIC_URL + '/',
                     referer:  referer || ''
                 });
};
export const goToMainPage = (referer?: string) => {
    history.push({
                     pathname: process.env.PUBLIC_URL + '/main/',
                     referer:  referer || ''
                 });
};
export const goToShopPage = (referer?: string) => {
    history.push({
                     pathname: process.env.PUBLIC_URL + '/main/shop/shop/',
                     referer:  referer || ''
                 });
};
export const goToPaymentPage = (referer?: string) => {
    history.push({
                     pathname: process.env.PUBLIC_URL + '/main/shop/payment/',
                     referer:  referer || ''
                 });
};
export const goToConfirm = (referer?: string) => {
    history.push({
                     pathname: process.env.PUBLIC_URL + '/main/shop/confirm/',
                     referer:  referer || ''
                 });
};
