import React, {useState} from "react";
import {Trans}           from "react-i18next";
import i18next           from "i18next";

const Translator        = (props) => {
    const [rerender, setRerender] = useState(false);
    i18next.on('languageChanged', function(lng) {
        setRerender(!rerender);
    })

    return (<span onClick={props.onClick} className={props.className}><Trans i18nKey={props.content}
                                                                             values={props.with}/></span>);
}
Translator.defaultProps = {
    content:   '',
    with:      '',
    className: '',
    onClick:   () => {
    }
};

export default Translator;
