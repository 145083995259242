import {
    ADD_TO_CART,
    CLEAR_MESSAGE,
    DEFAULT_MESSAGE_TIMEOUT,
    GENERATE_IDEMPOTENCY_KEY,
    REMOVE_FROM_CART,
    RESET_TERMINAL,
    SET_INVENTORY,
    SET_SHOP_FILTER,
    SHOW_MESSAGE,
    TOGGLE_NO_RESERVATION,
    TOGGLE_SPINNER,
    CHANGE_LOCALE
} from '../constants/constants.js';
import {CLEAR_CART, SET_HOTEL_CONFIG, SET_SHOP_ACTIVE_FILTER, TOGGLE_HELP} from "../constants/constants";
import * as Sentry from "@sentry/react";
// @formatter:off


export const setHotelConfig = (config) => {
    return {type: SET_HOTEL_CONFIG, data: config}
};
export const addToCart = (item) => {
    return { type: ADD_TO_CART, data:{item: item} };
};
export const generateIdempotencyKey = () => {
    return { type: GENERATE_IDEMPOTENCY_KEY };
};
export const removeFromCart = (key) => {
    return { type: REMOVE_FROM_CART, data:{key: key} };
};
export const clearCart = () => {
    return { type: CLEAR_CART };
};
export const setInventory = (inventory,lastModified,crossSelling) => {
    return { type: SET_INVENTORY, data: {inventory: inventory, lastModified: lastModified, crossSelling: crossSelling } };
};
export const setShopFilter = (filter) => {
    return { type: SET_SHOP_FILTER, data: {filter: filter} };
};
export const setShopActiveFilter = (activeFilter) => {
    return { type: SET_SHOP_ACTIVE_FILTER, data: {activeFilter: activeFilter} };
};
export const toggleNoReservation = (noReservation) => {
    return { type: TOGGLE_NO_RESERVATION, data: {noReservation: noReservation} };
};
export const changeLocale = (locale) => {
    return { type: CHANGE_LOCALE, data: {locale: locale} };
};

//TODO: use it
export const resetTerminal = () => {
    return { type: RESET_TERMINAL };
};
export const showError = (message, timeout = DEFAULT_MESSAGE_TIMEOUT) => {
    return { type: SHOW_MESSAGE, data: {message: message, type: 'error', timeout: timeout} };
};
export const showWarning = (message, timeout = DEFAULT_MESSAGE_TIMEOUT) => {
    return { type: SHOW_MESSAGE, data: {message: message, type: 'warning', timeout: timeout} };
};
export const clearMessage = () => {
    return { type: CLEAR_MESSAGE };
};
export const toggleSpinner = (showLoader, loaderMessage) => {
    return { type: TOGGLE_SPINNER, data: {showLoader:showLoader, loaderMessage:loaderMessage} };
};
export const toggleHelp = (showHelp) => {
    return { type: TOGGLE_HELP, data: {showHelp:showHelp} };
};
// @formatter:on


export const updateInventory = function() {
    return function(dispatch, getState) {
        return new Promise((resolve, reject) => {
            let state = getState();
            state.app.apiClient.getInventory(state.app.inventory.lastModified).then(response => {
                if (response) {
                    if (response.status && response.status === 'success' && response.inventory && response.crossSelling) {
                        dispatch(setInventory(response.inventory, response.lastModified, response.crossSelling));
                    }
                }
                resolve();
            }).catch((err) => {
                Sentry.captureException(err);
                reject(err);
            });
        });
    };
};

export const submitCart = function(roomnumber: string, noReservation: boolean) {
    return function(dispatch, getState) {
        return new Promise((resolve, reject) => {
            let state = getState();
            if (state.app.cart.list.length) {
                state.app.apiClient.beginPurchase(roomnumber, noReservation, state.app.cart)
                     .then(response => {
                         resolve(response);
                     })
                     .catch((err) => {
                         Sentry.captureException(err);
                         reject(err);
                     });
            } else {
                resolve('empty-list');
            }
        });
    };
};
