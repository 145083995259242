import React, { FC } from 'react';
import Currency from "./Currency";
import Translator from "./Translator";

export type ItemProps = {
    name: string,
    item: any,
    priceAsDeposit?: boolean,
    onClick: (item: any) => void
};

export const Item: FC<ItemProps> = (props) => {
    const {
        name,
        item,
        priceAsDeposit = false,
        onClick
    } = props;

    const itemHasPrice = item.price && item.price > 0;

    return (
        <li className="kiosk-item" onClick={() => onClick(item)}>
            <div className="__image">
                <img src={item.image} alt={name}/>
            </div>
            <div className="__name">{name}</div>
            <div className="__price">
                {itemHasPrice
                    ? (
                        <>
                            <Currency quantity={item.price} currency={item.currency}/>
                            {priceAsDeposit && (
                                <>
                                    &nbsp;
                                    <Translator content={"item.deposit"} />
                                </>
                            )}
                        </>
                    )
                    : (
                        <Translator content={"item.free"} />
                    )
                }

            </div>
        </li>
    );
}
