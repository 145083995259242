// @flow
import React, {Component}          from 'react';
import Header                      from "../Header/Header";
import {connect}                   from "react-redux";
import {
    clearCart, showError, showWarning, submitCart, toggleSpinner
}                                  from "../../redux/actions";
import {goToConfirm, goToMainPage} from "../Types/Dispatcher";
import * as Sentry                 from "@sentry/react";
import FormInputTextWithLabel      from "../../components/form/FormInputTextWithLabel";
import Translator                  from "./../../components/Translator";
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import amexSVG       from '../../styles/images/payment/payment-amex.svg';
import apple_paySVG  from '../../styles/images/payment/payment-apple-pay.svg';
import ecSVG         from '../../styles/images/payment/payment-ec.svg';
import google_paySVG from '../../styles/images/payment/payment-g-pay.svg';
import mastercardSVG from '../../styles/images/payment/payment-mastercard.svg';
import visaSVG       from '../../styles/images/payment/payment-visa.svg';

let animationData = require('./payment_stayery_kiosk.json');

class Payment extends Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            waiting:    false,
            roomnumber: ''
        };

        this.handleFormInput        = this.handleFormInput.bind(this);
        this.handleFormSubmit       = this.handleFormSubmit.bind(this);
        this.handleRoomnumberChange = this.handleRoomnumberChange.bind(this);
        this.toggleWaiting          = this.toggleWaiting.bind(this);
    }

    handleRoomnumberChange(event)
    {
        event.preventDefault();
        event.stopPropagation();

        this.setState({roomnumber: event.target.value});
    }

    componentDidMount()
    {
        if (this.props.cart.list.length === 0) {
            goToMainPage('Payment');
        } else if (this.props.cart.noReservation) {
            this.handleFormSubmit();
        }
    }

    handleFormInput(event)
    {
        let booking                = this.state.booking;
        booking[event.target.name] = event.target.value;
        this.handleBookingChanged(booking);
    }

    toggleWaiting(waiting)
    {
        this.setState({waiting: waiting})
    }


    handleFormSubmit()
    {

        if (this.state.roomnumber !== '' || this.props.cart.noReservation) {
            this.toggleWaiting(true, '');
            this.props.submitCart(this.state.roomnumber, this.props.cart.noReservation).then(response => {
                this.toggleWaiting(false, '');
                if (response.message) {
                    switch (response.status) {
                        case'notFound':
                            this.props.showError('errors.notFound');
                            break;
                        case 'success':
                            this.props.clearCart();
                            goToConfirm(this.props.referer || 'unknown');
                            break;
                        default:
                            this.props.clearCart();
                            break;
                    }
                } else if (response.error) {
                    switch (response.status) {
                        case 'payment-canceled':
                            this.props.showWarning('warning.payment-canceled');
                            break;
                        case 'payment-pending':
                        case 'payment-unknown':
                        case 'payment-failure':
                        case 'idempotencyKey':
                        default:
                            this.props.clearCart();
                            this.props.showError('errors.general');
                            break;
                    }
                } else {
                    this.props.showError('errors.general');
                }
            }).catch(err => {
                this.toggleWaiting(false, '');
                this.props.showError('errors.general');
                this.props.clearCart();
                Sentry.captureException(err);
            });
        } else {
            this.props.showError('messages.errors.required')
        }
        return false;
    }

    render()
    {
        let headerTitle = 'pageTitles.paymentPage.base';
        if (this.state.waiting) {
            headerTitle = 'pageTitles.paymentPage.waiting';
        }

        return (<div className="page page-payment">
            <div className="page-inner-wrapper">

                <Header
                    backButtonVisible={!this.state.waiting}
                    headerTitle={headerTitle}
                />

                <div className="content-wrapper">
                    <div className="container">
                        <div className="payment-waiting" hidden={!this.state.waiting}>
                            <div className="payment-methods">
                                <img alt="amex" src={amexSVG}/>
                                <img alt="apple_pay" src={apple_paySVG}/>
                                <img alt="ec" src={ecSVG}/>
                                <img alt="google_pay" src={google_paySVG}/>
                                <img alt="mastercard" src={mastercardSVG}/>
                                <img alt="visa" src={visaSVG}/>
                            </div>
                            <div className="__animation">
                                <Player
                                    autoplay
                                    loop
                                    src={animationData}
                                />
                            </div>
                        </div>
                        <div className="row" hidden={this.state.waiting}>
                            <div className="col-6">
                                <div className="text-tablet-24">
                                    <Translator content="payment.text"/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="stayery-form">
                                    {(() => {
                                        if (!this.props.cart.noReservation) {
                                            return <div>
                                                <FormInputTextWithLabel
                                                    label="fields.roomnumber"
                                                    name={"roomnumber"}
                                                    value={this.state.roomnumber}
                                                    onChange={this.handleRoomnumberChange}
                                                />
                                            </div>
                                        }
                                    })()}
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            this.handleFormSubmit();
                                        }}
                                        className="btn btn-stayery-black btn-uppercase btn-fullwidth btn-space"
                                    >
                                        <Translator content={"buttons.iWantToPay"}/>
                                        <i className="icon icon-stayery-arrow-r"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default connect(state => {
    return {
        pathname:  state.router.location.pathname,
        apiClient: state.app.apiClient,
        cart:      state.app.cart
    }
}, {
                           toggleSpinner,
                           clearCart,
                           showError,
                           showWarning,
                           submitCart
                       })(Payment);

