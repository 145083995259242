import React              from 'react';
import ReactDOM           from 'react-dom';
import App                from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter}    from 'react-router-dom';
import {Provider}         from 'react-redux';

import langEn from './languages/en';
import langDe from './languages/de';

import store from './redux/store';

import * as Sentry    from '@sentry/react';
import {Integrations} from '@sentry/tracing';

import i18n               from "i18next";
import {initReactI18next} from "react-i18next";

Sentry.init({
                dsn:              "https://aef4aea597cd41949132493d6167b70a@o108473.ingest.sentry.io/5407384",
                integrations:     [
                    new Integrations.BrowserTracing()
                ],
                tracesSampleRate: 1.0
            });
export const DEFAULT_LANG = 'de';
let counterpart           = require('counterpart');
counterpart.registerTranslations('en', langEn);
counterpart.registerTranslations('de', langDe);
counterpart.setLocale(DEFAULT_LANG);
let options = {
    lng:       DEFAULT_LANG,
    languages: ['en', 'de'],
    resources: {
        en:            {
            translation: langEn
        },
        de:            {
            translation: langDe
        },
        interpolation: {
            escapeValue: false
        },
        fallbackLng:   DEFAULT_LANG
    }
};
i18n.use(initReactI18next).init(options);

ReactDOM.render(<Provider store={store}>
    <BrowserRouter>
        <App/>
    </BrowserRouter>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
