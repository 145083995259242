import React from "react";

let currencyFormatter = require('currency-formatter');

const Currency        = (props) => {
    return (<span className={props.className}>
        {currencyFormatter.format(props.quantity, {code: props.currency})}
    </span>);
}
Currency.defaultProps = {
    quantity: 0,
    currency: 'EUR'
};

export default Currency;
